:root {
  --color-black: #000;
  --color-white: #fff;
  --color-accent: #e1cdb5;
  --color-cream: #f9f9f9;
  --color-gray: #dadce0;
  --color-dark: #2f353a;
  --color-danger: #eb1c26;
  --color-success: #02b875;
  --color-background: var(--color-cream);
  --color-foreground: var(--color-dark);
  --font-sans: "Avenir", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  --base-font-size: 14px;
  --radius: 3px;
  --border: 1px solid var(--color-gray);
  --shadow: 0px 7px 17px rgba(0, 0, 0, 0.03);
  --base-spacing: 30px;
}

@font-face {
  font-family: "Avenir";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(./assets/Avenir-Regular.woff2) format("woff2"),
    url(./assets/Avenir-Regular.woff) format("woff");
}

@font-face {
  font-family: "Avenir";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(./assets/Avenir-Bold.woff2) format("woff2"),
    url(./assets/Avenir-Bold.woff) format("woff");
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  min-height: 100vh;
  padding-bottom: calc(var(--base-spacing) * 3);
  font-family: var(--font-sans);
  font-size: var(--base-font-size);
  color: var(--color-foreground);
  background-color: var(--color-background);
}

ul,
ol {
  padding: 0;
  list-style: none;
}

h2 {
  font-size: 24px;
}

a {
  color: var(--color-accent);
}

.container {
  margin-left: auto;
  margin-right: auto;
  padding: var(--base-spacing);
  max-width: 500px;
}

.form-control {
  display: flex;
  align-items: center;
  width: 100%;
  height: 50px;
  padding-left: calc(var(--base-spacing) / 2);
  padding-right: calc(var(--base-spacing) / 2);
  appearance: none;
  outline: none;
  font-family: var(--font-sans);
  font-size: 16px;
  border: var(--border);
  border-radius: var(--radius);
  box-shadow: var(--shadow);
  color: inherit;
  background-color: var(--color-white);
  transition: all 0.1s ease-in;
}

.form-control:hover,
.form-control:focus {
  border-color: var(--color-accent);
}

label {
  display: inline-block;
  margin-bottom: 5px;
}

.form-group {
  margin-bottom: calc(var(--base-spacing) / 2);
}

.radio-buttons {
  display: flex;
}

.radio-buttons input[type="radio"] {
  position: absolute !important;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  width: 1px;
  border: 0;
  overflow: hidden;
}

.radio-buttons label {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  margin-bottom: 0;
  padding: 15px 5px;
  font-size: 16px;
  text-align: center;
  border: var(--border);
  border-radius: var(--radius);
  box-shadow: var(--shadow);
  background-color: var(--color-white);
  cursor: pointer;
  transition: all 0.1s ease-in;
}

.radio-buttons label:not(:last-child) {
  margin-right: calc(var(--base-spacing) / 2);
}

.radio-buttons input:checked + label {
  background-color: var(--color-accent);
  border-color: var(--color-accent);
  color: var(--color-white);
  font-weight: bold;
}

.form-check {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}

.form-check input {
  clip: rect(0px, 0px, 0px, 0px);
  height: 1px;
  width: 1px;
  white-space: nowrap;
  position: absolute;
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  margin: -1px;
  padding: 0px;
  overflow: hidden;
}

.form-check-box {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  background: var(--color-white);
  border: var(--border);
  box-shadow: var(--shadow);
  border-radius: var(--radius);
}

.form-check-box svg {
  width: 14px;
  height: 14px;
  color: currentcolor;
  display: inline-block;
  vertical-align: middle;
  flex-shrink: 0;
  opacity: 0;
}

.form-check input:checked + .form-check-box {
  background-color: var(--color-accent);
  border-color: var(--color-accent);
  color: var(--color-white);
}

.form-check input:checked + .form-check-box svg {
  opacity: 1;
}

.form-check-label {
  margin-left: 10px;
  user-select: none;
}

.button {
  position: relative;
  display: inline-flex;
  appearance: none;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 56px;
  margin: 0;
  user-select: none;
  white-space: nowrap;
  font-family: inherit;
  font-size: 16px;
  font-weight: bold;
  background-color: var(--color-dark);
  color: var(--color-white);
  border: 0;
  border-radius: 56px;
  transition: all 0.1s ease-in;
  cursor: pointer;
}

.button:hover {
  background-color: var(--color-black);
}

.button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.button__arrow {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}

.bottom-button {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  background-color: var(--color-white);
  padding-left: var(--base-spacing);
  padding-right: var(--base-spacing);
  padding-top: calc(var(--base-spacing) / 2);
  padding-bottom: calc(var(--base-spacing) / 2);
  box-shadow: 0px -1px 0px #e3e5e8;
}

.bottom-button .button {
  max-width: 440px;
}

.button-link {
  position: relative;
  display: inline-flex;
  appearance: none;
  margin: 0;
  padding: 0;
  user-select: none;
  font-family: inherit;
  font-size: 14px;
  text-decoration: underline;
  color: var(--color-accent);
  background-color: transparent;
  border: 0;
  cursor: pointer;
}

.error {
  text-align: center;
  margin-top: calc(var(--base-spacing) * -0.5);
  color: var(--color-danger);
}

.grid-two-col {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: calc(var(--base-spacing) / 2);
}

.u-mb-base {
  margin-bottom: var(--base-spacing);
}

.u-mb-base-half {
  margin-bottom: calc(var(--base-spacing) / 2);
}

.u-mb-base-double {
  margin-bottom: calc(var(--base-spacing) * 2);
}

.u-mt-base {
  margin-top: var(--base-spacing);
}

.u-mt-base-half {
  margin-top: calc(var(--base-spacing) / 2);
}
